import { Divider } from "@nextui-org/divider"
import {
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from "@nextui-org/navbar"
import clsx from "clsx"
import NextLink from "next/link"
import React, { useCallback } from "react"

import { fontMontserrat } from "@/config/fonts"

type MenuItem = {
  title: string
  items: {
    label: string
    href: string
  }[]
}

type HamburgerMenuProps = {
  menuItems: MenuItem[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
  menuItems,
  isOpen,
  setIsOpen,
}) => {
  const handleLinkClick = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <div className="h-5 w-5">
      <NavbarMenuToggle aria-label={isOpen ? "Close menu" : "Open menu"} />
      <NavbarMenu className="flex pb-10 lg:items-center">
        <div className="flex flex-col justify-center gap-10 px-5">
          {menuItems.map((menuItem) => (
            <div
              key={menuItem.title}
              className="flex flex-col items-start gap-4"
            >
              <NavbarMenuItem>
                <p
                  className={clsx(
                    "font-mono text-xl font-bold text-foreground data-[active=true]:font-medium",
                    fontMontserrat.variable,
                  )}
                >
                  {menuItem.title}
                </p>
              </NavbarMenuItem>
              {menuItem.items.map((item) => (
                <NavbarMenuItem key={`${menuItem.title}-${item.label}`}>
                  <NextLink
                    href={item.href}
                    className={clsx(
                      "font-mono font-bold text-foreground hover:text-primary data-[active=true]:font-medium data-[active=true]:text-primary",
                      fontMontserrat.variable,
                    )}
                    onClick={handleLinkClick}
                  >
                    {item.label}
                  </NextLink>
                </NavbarMenuItem>
              ))}
              <Divider />
            </div>
          ))}
        </div>
      </NavbarMenu>
    </div>
  )
}

export default HamburgerMenu
